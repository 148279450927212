import "./App.css";

import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { killsQuery } from "./GetLatsetGuildKills";
import { deathsQuery } from "./GetLatsetGuildDeaths";

function GuildChars({ id, name }) {
  const [killers, setKillers] = useState(null);
  const [victims, setVictims] = useState(null);

  const [players, setPlayers] = useState([]);
  const { data, loading, error } = useQuery(killsQuery, {
    variables: {
      first: 50,
      id: id,
    },
    onCompleted: (data) => {
      console.log(data);
      const allKills = data.kills.nodes;
      const _players = [];
      // map unique attackers.character.names to players with most recent time
      allKills.forEach((kill) => {
        const attacker = kill.attackers[0].character.name;
        const time = kill.time;
        const player = _players.find((player) => player.name === attacker);
        if (player) {
          if (time > player.time) {
            player.time = time;
          }
        } else {
          _players.push({
            name: attacker,
            time,
            career: kill.attackers[0].character.career,
            level: kill.attackers[0].level,
          });
        }
      });

      setKillers(_players);
    },
  });

  const { data2, loading2, error2 } = useQuery(deathsQuery, {
    variables: {
      first: 50,
      id: id,
    },
    onCompleted: (data2) => {
      console.log(data2);
      const allKills = data2.kills.nodes;
      const _players = [];
      // map unique attackers.character.names to players with most recent time
      allKills.forEach((kill) => {
        const victim = kill.victim.character.name;
        const time = kill.time;
        const player = _players.find((player) => player.name === victim);
        if (player) {
          if (time > player.time) {
            player.time = time;
          }
        } else {
          _players.push({
            name: victim,
            time,
            career: kill.victim.character.career,
            level: kill.victim.level,
          });
        }
      });

      setVictims(_players);
    },
  });

  useEffect(() => {
    if (killers && victims) {
      const _players = [];
      // merge killesr and victims to unique players
      const allPlayers = [...killers, ...victims];
      allPlayers.forEach((player) => {
        const existingPlayer = _players.find(
          (_player) => _player.name === player.name
        );
        if (existingPlayer) {
          if (player.time > existingPlayer.time) {
            existingPlayer.time = player.time;
          }
        } else {
          _players.push(player);
        }
      });

      // order _players by time
      _players.sort((a, b) => b.time - a.time);

      setPlayers(_players);
    }
  }, [killers, victims]);

  const timeAgo = (timestamp) => {
    const now = new Date();
    const date = new Date(timestamp * 1000);

    const diff = now - date;
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return `${days} days ago`;
    }
    if (hours > 0) {
      return `${hours} hours ago`;
    }
    if (minutes > 0) {
      return `${minutes} minutes ago`;
    }
    if (seconds > 0) {
      return `${seconds} seconds ago`;
    }
  };

  const recent = (timestamp) => {
    const now = new Date();
    const date = new Date(timestamp * 1000);
    const diff = now - date;
    const minutes = Math.floor(diff / 1000 / 60);

    if (minutes <= 59) {
      return "font-bold";
    }

    return "opacity-90";
  };

  const icon = (career) => {
    const CAREER_NAME_MAP = {
      IRON_BREAKER: "IRONBREAKER",
      SWORD_MASTER: "SWORDMASTER",
      KNIGHT_OF_THE_BLAZING_SUN: "KNIGHT",
      BLACK_GUARD: "BLACKGUARD",
      DISCIPLE_OF_KHAINE: "DISCIPLE",
    };

    career = CAREER_NAME_MAP[career] || career;
    const img = career.toLowerCase().replace("_", "-");
    return <img className="w-6" src={`/images/icons/${img}.png`} />;
  };

  return (
    <div className="text-xs lg:w-80">
      <div className="my-5 text-lg font-bold text-center">&lt;{name}&gt;</div>
      {(loading || loading2) && <div className="text-center">Loading...</div>}
      {error || (error2 && <pre>{error.message}</pre>)}

      {!loading &&
        !loading2 &&
        players.map((player) => (
          <div className={`grid grid-cols-7 mb-0.5 ${recent(player.time)}`}>
            <div>{icon(player.career)}</div>
            <div className={`col-span-3`}>
              {player.name}{" "}
              <span className="text-white">
                {player.level < 40 ? `(${player.level})` : ""}
              </span>
            </div>
            <div className="col-span-3">{timeAgo(player.time)}</div>
          </div>
        ))}
    </div>
  );
}

export default GuildChars;
