import "./App.css";

import GuildChars from "./GuildChars";

function App() {
  const urlParams = new URLSearchParams(window.location.search);

  const guild = urlParams.get("guild");

  let oGuild = { id: 3603, name: "Retribution" };
  let dGuild = { id: 2905, name: "Devastation" };
  let gIcon = "/images/RET_des-order_PNG.png";

  if (guild == "unreal") {
    oGuild = { id: 3592, name: "Unreal" };
    dGuild = { id: 3969, name: "XREAL" };
    gIcon = "/images/unreal.png";
  } else if (guild == "cross") {
    oGuild = { id: 3962, name: "Cross" };
    dGuild = { id: 4117, name: "Two Girls One Coupe" };
    gIcon = "/images/cross.png";
  }

  return (
    <div className="main">
      <div className="container mx-auto py-5">
        <img className="w-52 mx-auto" src={gIcon} />
        <div className="mx-auto md:flex justify-between relative md:-top-40 max-w-5xl py-5">
          <GuildChars id={oGuild.id} name={oGuild.name} />
          {dGuild && <GuildChars id={dGuild.id} name={dGuild.name} />}
        </div>
      </div>
    </div>
  );
}

export default App;
